<template>
  <section class="views">
    <!-- header -->
    <Headers select="3"></Headers>

    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner_team.jpg')"
      />
      <div class="posters_ewm" v-if="false">
        <div class="ewm">
          <div class="ewm2">
            <div class="ewm3">
              <img :src="require('@/assets/image/csewm.png')" alt="" />
            </div>
            <div class="ewm3_value">掃碼報名比賽</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="viewsBoxTab">
        <div class="viewsTab">
          <ul>
            <li
              v-for="(item, index) in tabColumns"
              :key="index"
              @click="tabBut(index)"
              :class="select == index ? 'select' : ''"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="shortlisted-query" v-if="false">
        <div class="contentFrom_title">入圍名單查詢</div>
        <div class="plateUlsCentent6">
          <ul>
            <li>
              <!-- <div class="prompt" style="cursor: pointer">
                <div
                  :class="choose == 0 ? 'highlight' : ''"
                  @click="chooseBut(0)"
                >
                  個人報名
                </div>
                <div
                  :class="choose == 1 ? 'highlight' : ''"
                  @click="chooseBut(1)"
                >
                  學校報名
                </div>
              </div> -->
              <div class="promptInput2" v-if="choose == 0">
                <div class="lis">
                  <div class="lisLife">
                    <!-- <img :src="require('@/assets/image/hk.png')" />
										<span>+852</span> -->
                    <CountryCodeSelector
                      :searchbar="false"
                      :countryCode.sync="prefix"
                      style="width: 80px; margin-right: 5px"
                    ></CountryCodeSelector>
                  </div>
                  <div class="lisRight">
                    <input
                      type="text"
                      placeholder="請輸入參賽電話號碼"
                      value=""
                      v-model.trim="from.phone"
                    />
                  </div>
                </div>
              </div>
              <div class="promptInput" v-if="choose == 1">
                <input
                  placeholder="請輸入學校編號"
                  v-model.trim="from.schoolCode"
                />
                <!-- <input placeholder="請輸入參賽郵箱" v-model.trim="from.email" /> -->
              </div>
            </li>
            <li v-if="choose == 0">
              <!-- <div class="prompt">姓名</div> -->
              <div class="promptInput">
                <input
                  placeholder="請輸入聯絡人姓名"
                  v-model="from.school_name"
                />
              </div>
            </li>
            <template v-if="choose == 1">
              <li>
                <!-- <div class="prompt">編號</div> -->
                <div class="promptInput">
                  <input
                    placeholder="請輸入學生編號"
                    v-model="from.studentCode"
                  />
                </div>
              </li>
              <li>
                <!-- <div class="prompt">密碼</div> -->
                <div class="promptInput">
                  <input placeholder="請輸入學生密碼" v-model="from.password" />
                </div>
              </li>
            </template>

            <li style="width: 20%">
              <div class="but" @click="finalistsBut ? finalists() : ''">
                查詢
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="viewTabContent">
        <div class="ulsTab" ref="tabSlideTwo">
          <ul>
            <li
              v-for="(item, index) in tabColumns[select].dataTab"
              :key="index"
              :class="selectTwo == index ? 'select' : ''"
              @click="tabButTwo(index)"
              :ref="`tab${index}RefTwo`"
            >
              <div>{{ item.name }}</div>
            </li>
          </ul>
        </div>

        <div class="box">
          <ul>
          <!-- <ul v-if="select != 0"> -->
            <div class="wu">
              <img :src="require('@/assets/image/hodgepodge/wu.png')" alt="" />
              <span> 當前階段成績尚未揭曉哦 </span>
            </div>
          </ul>
          <!-- <ul v-if="select == 0">
            <li class="weiyitop">
              <div>參賽序號</div>
              <div>姓名</div>
              <div>電話/學號</div>
            </li>

            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>{{ item.competitionId }}</div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.studentCode || item.phone }}</div>
            </li>
          </ul> -->
          <!-- <ul v-if="select === 2">
            <li class="weiyitop">
              <div>獎項</div>
              <div>姓名</div>
              <div>電話</div>
            </li>
            <li class="lis" v-for="(item, index) in queryUserList" :key="index">
              <div>
                {{
                  item.ranking == 1
                    ? '金獎'
                    : item.ranking == 2
                    ? '銀獎'
                    : item.ranking == 3
                    ? '銅獎'
                    : item.ranking > 3
                    ? '優異獎'
                    : ''
                }}
              </div>
              <div>{{ item.studentName }}</div>
              <div>{{ item.phone }}</div>
            </li>
          </ul> -->
        </div>
      </div>

      <!-- 佔位 -->
      <div style="margin-top: 20px; width: 100%"></div>
    </div>

    <!-- 未入圍/成功入園 -->
    <van-popup v-model="newShortZZC" :round="true" :lock-scroll="false">
      <div class="newShortBox">
        <div class="list" v-if="newShortActive == 1">
          <!-- <div class="lis lisCenten"> <van-image width="149px" :src="require('@/assets/image/fusai/yes.png')" /> </div> -->
          <div class="ulst">
            <ul>
              <li>姓名</li>
              <li>組別</li>
              <li>分數</li>
            </ul>
            <ul>
              <li>
                {{ userObj.studentName == null ? '' : userObj.studentName }}
              </li>
              <li>
                {{
                  userObj.competitionGroup == null
                    ? ''
                    : userObj.competitionGroup
                }}
              </li>
              <li>{{ userObj.score == null ? '' : userObj.score }}</li>
            </ul>
          </div>
          <div class="lis lisText">恭喜您，成功入圍複賽，<br />請仔細閱讀入圍電郵，上載參賽視頻。</div>
          <!-- <div class="but" style="margin: 0" @click="generateHeatsZZC = true">
            點擊查看複賽指引
          </div> -->
          <div class="view_guide" @click="generateHeatsZZC = true">
            點擊查看複賽指引
          </div>
        </div>

        <div class="list" style="padding:10px 0;" v-if="newShortActive == 2">
          <div class="ulst">
            <ul>
              <li>姓名</li>
              <li>組別</li>
              <li>分數</li>
            </ul>
            <ul>
              <li>
                {{ userObj.studentName == null ? '' : userObj.studentName }}
              </li>
              <li>
                {{
                  userObj.competitionGroup == null
                    ? ''
                    : userObj.competitionGroup
                }}
              </li>
              <li>{{ userObj.score == null ? '' : userObj.score }}</li>
            </ul>
          </div>
          <div class="lis lisText">
            很遺憾，您的成績未能入圍複賽，<br />非常感謝您的參與。
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 複賽指引 -->
    <div class="generateHeats">
      <van-popup v-model="generateHeatsZZC" :round="true" :lock-scroll="false">
        <div class="generateHeatsBox">
          <div class="uls">
            <div class="titleH1">
              <div class="left_icon">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div class="title">複賽指引</div>
              <div class="right_icon">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>

            <div class="textCentent">
              <div class="color1 font_size1" style="margin-bottom: 6px">
                請按照下方參賽影片要求進行錄製，否則將影響評分。如提交影片遇到困難，請于活動頁左下方WhatsApp進行留言，主辦機構中文路ChineseRd將會提供支援。
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                *凡是參賽的選手，等同於同意主辦機構中文路ChineseRd將此次參賽影片使用於全媒體渠道展示宣傳。
                <br />
                *為免遇上網絡擠塞，請預留好上載複賽影片的時間。
                <br />
                *所有參賽影片錄製必須為參賽者本人，如審核發現非參賽者本人錄製之情況，將予以失格公示。
                <br />
              </div>
              <div class="titleRed">【參賽影片要求】</div>
              <div class="color1 font_size1" style="margin-bottom: 6px">
                1. 參賽者應依照以下次序錄製影片：
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                ① 用普通話進行自我介紹，包含姓名、組別、來自哪一間幼稚園/小學/中學等。
                <br />
                ② 用普通話讀出比賽誦材之標題及作者（如若誦材未標明作者，可以只讀誦材標題）。
                <br />
                ③ 用普通話朗誦誦材內容一次。
                <br />
                ④ 參賽者必須背誦誦材，違者將只獲評語，沒有評級，亦不獲發獎狀。
                <br />
                ⑤ 如參賽者於影片中重複演繹同一作品，只有首次演繹獲得評審。
                <br />
                ⑥ 請使用豎屏拍攝。
                <br />
                ⑦ 影片的時長要求：幼稚園組、小學組3分鐘以內，中學組5分鐘以內。
                <br />
              </div>
              <div class="color1 font_size1" style="margin-bottom: 6px">
                2. 參賽者必須根據以下指引，否則將影響評分：
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                ① 參賽者錄製參賽影片時請注意着裝得體。
                <br />
                ② 整段影片須清楚顯示參賽者之全身、樣貌及動作。
                <br />
                ③ 拍攝影片時，應保持拍攝鏡頭穩定及定鏡拍攝，不應移動、推進及拉近鏡頭。
                <br />
                ④ 為確保影片聲畫同步、畫面與聲音清晰和流暢，影⽚解像度為720p(1280x720)或以上。
                <br />
                ⑤ 在室內以簡單背景和寧靜的環境下拍攝，並確保光度充足，避免背光、使用虛擬或投射背景。
                <br />
                ⑥ 參賽者不應使用任何款式耳機作為收音用途。
                <br />
                ⑦ 拍攝影片時請勿使用擴音器。
                <br />
              </div>

              <div class="color1 font_size1" style="margin-bottom: 6px">
                3. 參賽者必須跟隨以下指引，否則將被取消資格：
              </div>
              <div class="color2 font_size2" style="margin-bottom: 20px">
                ① 影片必須為一鏡到底的拍攝原片，拍攝途中不可暫停攝影機。
                <br />
                ② 影片中不可加入特別效果，例如濾鏡、過渡、調色、調光、字幕等，亦不得進行任何混音、剪接及後期製作。
                <br />
                ③ 影片必須是現場收音及錄像，不可配音。
                <br />
              </div>
              <div class="titleRed">【影片上傳要求】</div>
              <div class="font_size2" style="margin-bottom: 20px">
                <span class="color1"
                  >① 參賽者須通過本次活動報名系統登錄並上傳參賽影片。</span
                >
                <br />
                <span class="color1"
                  >② 參賽者有責任確保以下資料正確，包括：</span
                >
                <br />
                <span class="color2">
                  網頁中的各項資料正確。如發現資料有誤，請于活動頁左下方WhatsApp進行留言，主辦機構中文路ChineseRd將會提供支援，根據指引於限期內重新提交。
                </span>
                <br />
                <span class="color2"
                  >所提交的影片須與參賽者及其比賽項目相符。如記錄不符，評審團有權不評審相關影片。</span
                >
                <br />
                <span class="color1"
                  >③ 如重複提交，將以最後一次符合提交條件的記錄為準。</span
                >
                <br />
                <span class="color1"
                  >④
                  如評審期內因任何原因，影片未能播放，不會就此作個別通知，參賽者將不獲評分（評級）或獎狀。</span
                >
                <br />
              </div>
              <!-- <div class="but" @click="handleUploadVideo()">
                <img :src="require('@/assets/image/fusai/yunshang.png')" />
                點擊上載視頻
              </div> -->
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </section>
</template>
	 
	<script>
import Headers from '@/components/header/pc_header_team'
import CountryCodeSelector from '@/components/CountryCodeSelector'
export default {
  name: 'achievement_team',
  data() {
    return {
      tabColumns: [
        {
          id: '0',
          name: '初賽成績',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K2'
            },
            {
              name: '小學組',
              fzhu: 'P1-P2'
            },
            {
              name: '中學組',
              fzhu: 'S1-S3'
            },
          ]
        },
        {
          id: '1',
          name: '總決賽成績',
          dataTab: [
            {
              name: '幼稚園組',
              fzhu: 'K1-K2'
            },
            {
              name: '小學組',
              fzhu: 'P1-P2'
            },
            {
              name: '中學組',
              fzhu: 'S1-S3'
            },
          ]
        },
      ],
      select: 0,
      selectTwo: 0,
      choose: 0, //查詢的下標
      from: {
        school_name: null,
        email: null,
        phone: null,
        schoolCode: null,
        studentCode: null,
        password: null
      },
      prefix: 852,
      finalistsBut: true,
      newShortZZC: false, //入圍 彈窗
      newShortActive: 1,
      userObj: {
        competitionGroup: 1, //組別
        score: null, //分數
        studentName: null //名字
      },
      queryUserList: [],
      generateHeatsZZC: false
    }
  },
  computed: {},
  components: {
    Headers,
    CountryCodeSelector
  },
  watch: {},
  created() {},
  mounted() {
    // this.getScoreList(1)
  },
  methods: {
    tabButTwo(e) {
      this.selectTwo = e
      // if (this.select == 0) {
      //   this.getScoreList(1)
      // }
    },
    tabBut(e) {
      this.select = e
      // if (this.select != 2) {
      // this.getScoreList(1)
      // }
    },
    // 切換
    chooseBut(e) {
      this.choose = e
    },
    validator_email(val) {
      const email = val.trim()
      return /^[A-Za-z0-9]+([_\-\.][A-Za-z0-9]+)*@([A-Za-z0-9\-\_]+\.)+[A-Za-z]{2,6}$/.test(
        email
      )
    },
    validator_phone(val) {
      const phone = val.trim()
      return /^[0-9]*$/.test(phone)
    },
    // 入圍按鈕提交
    finalists() {
      return
      let params

      if (this.choose == 0) {
        params = {
          studentName: this.from.school_name,
          prefix: this.prefix,
          phone: this.from.phone,
          email: null
        }
      } else if (this.choose == 1) {
        const { schoolCode, studentCode, password } = this.from
        if (schoolCode == '' || schoolCode == null) {
          this.$toast('請輸入學校編號 !')
          return
        }
        if (studentCode == '' || studentCode == null) {
          this.$toast('請輸入學生編號 !')
          return
        }
        if (password == '' || password == null) {
          this.$toast('請輸入學生密碼 !')
          return
        }

        params = {
          schoolCode,
          studentCode,
          password
        }
      }

      if (this.choose == 0) {
        if (params.studentName == '' || params.studentName == null) {
          this.$toast('請輸入您的姓名 !')
          return
        }
        // 手機
        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }

        if (!this.validator_phone(params.phone)) {
          this.$toast('手機號格式有誤 !')
          return
        }
        // } else if (this.choose == 1) {
        //   // 郵箱
        //   if (!this.validator_email(params.email)) {
        //     this.$toast('電郵格式有誤 !')
        //     return
        //   }
      }
      params.scoreType = this.select + 1
      this.finalistsBut = false
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      // 正式環境請求
      // this.$axios
      // .post(this.$api.getPersonInfo,params)

      // 使用代理的方式請求
      // this.$axios({
      // 	url: '/api/statistics/competition/getPersonInfo',
      // 	method: 'post',
      // 	data: params
      // })
      this.$axios
        .post(this.$api.getPersonInfo, params)
        .then((response) => {
          this.finalistsBut = true
          console.log(response)
          if (response.data.code == 500) {
            this.$toast.fail(response.data.msg)
          }
          if (response.data.code == 0) {
            //请求成功
            // this.$toast.success('獲取成功');
            this.userObj.competitionGroup = response.data.data.competitionGroup //組別
            this.userObj.score = response.data.data.score //分數
            this.userObj.studentName = response.data.data.studentName //名字

            this.$toast.clear()
            if (response.data.data.shortlisted) {
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 1 //1是成功  2是失敗
            } else {
              this.newShortZZC = true //入圍 彈窗
              this.newShortActive = 2 //1是成功  2是失敗
            }
          }
        })
        .catch((error) => {
          this.finalistsBut = true
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    getScoreList(pageNum) {
      this.$toast.loading({
        message: '正在查詢中...',
        duration: 0,
        forbidClick: true
      })
      let groupId = this.selectTwo + 1
      let scoreType = this.select + 1
      let params =
        '?groupId=' +
        groupId +
        '&scoreType=' +
        scoreType +
        '&pageNum=' +
        pageNum +
        '&pageSize=' +
        100
      if (this.select == 1) {
        params = params + '&ranking=' + 15
      }
      this.$axios
        .get(this.$api.promotionStudentList + params)
        .then((response) => {
          this.$toast.clear()
          let arr = response.data.rows
          if (pageNum === 1) {
            this.queryUserList = arr
          } else {
            this.queryUserList = [...this.queryUserList, ...arr]
          }
          // 数据最多返回100条，超出请求2次
          if (response.data.total > 100 && pageNum === 1) {
            this.getScoreList(2)
          }
        })
        .catch((error) => {
          //请求失败
          this.$toast.fail('error')
          console.log(error)
        })
    },
    handleUploadVideo() {
      this.$router.push({
        path: '/',
        query: {
          uploadVideo: true
        }
      })
    }
  }
}
</script>
	
	<style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;

  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      width: 1024px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: right;

      .ewm {
        width: 161px;
        height: 189px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 8px;
        box-sizing: border-box;

        .ewm2 {
          width: 100%;
          height: 100%;
          padding: 6px;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;

          .ewm3 {
            width: 132px;
            height: 132px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: auto;

    .viewsBoxTab {
      width: 100%;
      position: relative;
      background: #d34a00;

      .viewsTab {
        width: 100%;
        max-width: 1024px;
        margin: auto;
        box-sizing: border-box;
        display: flex;

        ul {
          width: 100%;
          height: 64px;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          li {
            width: 152px;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            flex: 0 0 auto;
            background: #d34a00;
            cursor: pointer;

            div {
              width: 100%;
            }

            div:nth-child(1) {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 0.33rem;
            }

            div:nth-child(2) {
              font-size: 11px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 13px;
            }
          }

          .select {
            font-size: 16px;
            font-weight: 600;
            color: #903300;
            padding: 8px 0;
            background: url('~@/assets/image/hodgepodge/tabbuttom.png')
              no-repeat;
            background-size: 100% 100%;

            div:nth-child(1) {
              font-size: 16px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
              line-height: 16px !important;
            }

            div:nth-child(2) {
              font-size: 13px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600 !important;
              color: #903300;
              line-height: 15.5px !important;
            }
          }
        }
      }
    }

    .viewTabContent {
      width: 100%;
      max-width: 1024px;
      margin: auto;
      margin-top: 36px;
      box-sizing: border-box;

      .ulsTab::-webkit-scrollbar {
        display: none;
      }

      .ulsTab {
        width: 100%;
        box-sizing: border-box;

        ul {
          width: 60%;
          display: inline-flex;
          justify-content: center;
          align-items: center;

          li {
            margin: 0 auto;
            // margin-right: 38px;
            width: 180px;
            height: 66px;
            background: #ffffff;
            border: 2px solid #ffffff;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            font-family: PingFang SC-Semibold, PingFang SC;
            line-height: 19px;
            text-align: center;
            border-radius: 16px;
            cursor: pointer;

            div:nth-child(1) {
              width: 100%;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              line-height: 19px;
            }

            div:nth-child(2) {
              font-size: 13px;
              font-family: PingFang SC-Regular, PingFang SC;
              color: #666666;
              margin-top: 2px;
            }
          }
          .select {
            background: #fdd472 !important;

            div:nth-child(1) {
              font-size: 16px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
              line-height: 19px;
            }

            div:nth-child(2) {
              font-size: 13px !important;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #903300 !important;
            }
          }
        }
      }

      .box {
        width: 700px;
        padding-top: 64px;
        margin: auto;
        box-sizing: border-box;

        ul::-webkit-scrollbar {
          display: none;
        }

        ul {
          width: 100%;
          height: 70vh;
          border-radius: 16px;
          background: #ffffff;
          border: 0.06rem solid #ffffff;
          box-sizing: border-box;
          overflow-x: hidden;
          position: relative;

          .wei {
            width: 100%;
            height: 46px;
          }

          .weiyitop {
            width: 100%;
            height: 46px;
            background: #ffb559;
            display: flex;
            position: sticky;
            left: 0;
            top: 0;

            div {
              width: 33%;
              text-align: center;
              font-size: 16px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #a94800;
              line-height: 46px;
            }
          }

          .lis {
            width: 100%;
            height: 46px;
            margin: auto;
            background: #ffffff;
            display: flex;
            border-bottom: 1px;

            div {
              width: 33%;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 46px;
              text-align: center;
            }
          }

          .wu {
            width: 100%;
            // height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;
            padding-top: 100px;

            img {
              width: 200px;
              margin-bottom: 30px;
            }

            span {
              width: 100%;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
.shortlisted-query {
  width: 100%;
  max-width: 1024px;
  margin: 70px auto 0 auto;
  position: relative;
  .contentFrom_title {
    width: 265px;
    height: 52px;
    margin: auto;
    border-radius: 9px;
    background: url('~@/assets/image/icon/titlebg.png') no-repeat;
    background-size: 100% 100%;
    font-size: 20px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 23px;
    line-height: 52px;
    position: absolute;
    top: -26px;
    left: 0;
    right: 0;
    margin: auto;

    text-shadow: 1.5px 1.5px 0 #ff782f, -1.5px 1.5px 0 #ff782f,
      1.5px -1.5px 0 #ff782f, -1.5px -1.5px 0 #ff782f;
  }
  .plateUlsCentent6 {
    width: 100%;
    padding: 35px 7px 20px 7px;
    border-radius: 8px 8px 8px 8px;
    box-sizing: border-box;
    background: #ffffff;

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-end;
      box-sizing: border-box;
      li {
        width: 30%;
        text-align: left;

        .prompt {
          height: 30px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #4d4d4d;
          line-height: 10px;
          margin-bottom: 12px;
          // padding-left: 13px;
          // box-sizing: border-box;
          display: flex;
          align-items: center;
          padding-left: 13px;
          box-sizing: border-box;

          div {
            margin-right: 11px;
            text-align: center;
            line-height: 30px;
            color: #999999;
          }

          .highlight {
            color: #4d4d4d !important;
            position: relative;
          }

          .highlight:after {
            content: ' ';
            width: 90%;
            height: 1px;
            background: #4d4d4d;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }

          span {
            font-size: 12px !important;
            color: #de2810;
          }
        }

        .lis {
          width: 100%;
          height: 34px;
          line-height: 34px;
          background: #f5f5f5;
          display: flex;
          border-radius: 6px 6px 6px 6px;
          // overflow: hidden;

          .lisLife {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #4a4a4a;
            padding-left: 10px;
            box-sizing: border-box;

            img {
              width: 16px;
              height: 11px;
            }
          }

          .lisRight {
            width: 100%;
            height: 100%;

            input {
              width: 100%;
              height: 100%;
              padding-left: 13px;
              box-sizing: border-box;
              border: none;
              background: #f5f5f5;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
            }
            input::-webkit-input-placeholder {
              color: #999999;
            }
            /* 使用webkit内核的浏览器 */
            input:-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本4-18 */
            input::-moz-placeholder {
              color: #999999;
            }
            /* Firefox版本19+ */
            input:-ms-input-placeholder {
              color: #999999;
            } /* IE浏览器 */
          }
        }

        .promptInput {
          width: 100%;
          height: 34px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 3px 3px 3px 3px;
          input {
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #f5f5f5;
            border-radius: 3px 3px 3px 3px;
            border: none;
            padding-left: 13px;
            box-sizing: border-box;
          }

          input::-webkit-input-placeholder {
            color: #999999;
          }
          /* 使用webkit内核的浏览器 */
          input:-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本4-18 */
          input::-moz-placeholder {
            color: #999999;
          }
          /* Firefox版本19+ */
          input:-ms-input-placeholder {
            color: #999999;
          } /* IE浏览器 */
        }

        .promptInput2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 34px;
          line-height: 34px;
          box-sizing: border-box;
          background: #f5f5f5;
          border-radius: 6px 6px 6px 6px;

          span {
            width: 100%;
            height: 100%;
          }
          .span_tw {
            color: rgb(153, 153, 153) !important;
          }
          .promptInputValue {
            width: 100%;
            height: 100%;
            padding-left: 13px;
            box-sizing: border-box;
            border: none;
            background: #f5f5f5;
            border-radius: 6px 6px 6px 6px;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
          }
        }
      }

      li:last-child {
        margin-top: 15px;
      }
    }

    .uls:first-child {
      margin-right: 60px;
    }

    .but {
      width: 140px;
      height: 52px;
      background: url('~@/assets/image/icon/but.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 52px;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.3s;
      letter-spacing: 2px;
      &:active {
        transform: scale(1.06);
      }
    }
  }
}
.newShortBox {
  width: 600px;
  height: 300px;
  padding-top: 24px;
  padding-bottom: 24px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;

  .list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;

    .lis {
      width: 100%;
      margin-bottom: 20px;
    }

    .lis:last-child {
      margin-bottom: 0px !important;
    }

    .lisCenten {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lisText2 {
      display: flex;
      justify-content: space-between;
      width: 65%;
      margin: auto;
      font-size: 14px;

      span {
        color: #ff5a25;
      }
    }

    .ulst {
      width: 80%;
      margin: auto;

      ul {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 3px 3px 3px 3px;

        li {
          width: 32%;
          text-align: center;
          font-size: 14px;
          padding: 0 5px;
        }
        li:nth-child(2) {
          width: 36% !important;
        }
      }

      ul:nth-child(1) {
        background: #ff5a25;
        height: 30px;
        line-height: 30px;

        li {
          height: 100%;
          color: #fff;
        }
      }

      ul:nth-child(2) {
        background: #feffeb;
        font-size: 9.8px !important;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 10px 0;
        li {
          color: #4e4e4e;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .lisText {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #4e4e4e;
      line-height: 24px;
    }

    .but {
      width: 140px;
      height: 40px;
      background: url('~@/assets/image/icon/but.png') no-repeat;
      background-size: 100%;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }
  }
}
/deep/ .CountryCodeSelectorContainer {
  width: 100px;
  .arrow-down {
    width: 12px;
    height: 12px;
  }
}
.generateHeatsBox {
  width: 960px;
  padding: 12px;
  box-sizing: border-box;
  background: #ffd123;
  border-radius: 16px 16px 16px 16px;
  .uls {
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    background: #fff;

    .titleH1 {
      width: 100%;
      font-size: 24px;
      color: #ff5a25;
      font-weight: 600;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0 15px 0;

      .right_icon {
        display: flex;
        align-items: center;
        div:nth-child(3) {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
        div:first-child {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-left: 7px;
        }
      }

      .left_icon {
        display: flex;
        align-items: center;
        div:first-child {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(2) {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
        div:nth-child(3) {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background-color: #ff5a25;
          margin-right: 7px;
        }
      }
    }

    .textCentent {
      width: 100%;
      text-align: left;
      line-height: 26px;
      font-family: PingFang SC-Medium, PingFang SC;

      .color1 {
        color: #ff5a25;
      }
      .color2 {
        color: #999999;
      }

      .font_size1 {
        font-size: 18px;
      }
      .font_size2 {
        font-size: 14px;
      }

      .titleRed {
        width: 180px;
        height: 40px;
        line-height: 40px;
        background: #ff5a25;
        border-radius: 3px 3px 3px 3px;
        text-align: center;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 12px;
      }

      .but {
        width: 180px;
        height: 40px;
        line-height: 40px;
        background: #ff7e18;
        font-size: 20px;
        font-family: PingFang SC-Semibold, PingFang SC;
        border-radius: 3px 3px 3px 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        margin: auto;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
  }
}
.generateHeats {
  .van-popup {
    z-index: 999999 !important;
  }
}
.view_guide {
  padding: 0 8px;
  width: 180px;
  height: 40px;
  line-height: 40px;
  background: #ff7e18;
  font-size: 20px;
  font-family: PingFang SC-Semibold, PingFang SC;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  // margin: auto;
  cursor: pointer;
}
</style>